import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";
export default {
  name: "buy-flow",
  computed:{
    ...mapGetters({
      isProfessional: 'profile/isProfessional',
      productsPdf: 'order/productsPdf',
      basket:'basket/basket',
      downloadPdfLoading:'order/downloadPdfLoading',
    })
  },
  methods:{
    ...mapActions({
      fetchPdf:`order/DOWNLOAD_PDF`,
    }),
    ...mapMutations({
      changeDefaultPricesPopup:`popups/CHANGE_DEFAULT_PRICES`,
    }),
   async convertPdf(){
      const response = await axios.get(this.productsPdf.path, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = 'example.pdf';
      link.click();
      URL.revokeObjectURL(link.href);
    },
    downloadPdf(){
      let obj = {
        products:[]
      }
      for(let i in this.basket){
        if (this.basket[i].select_type) {
          obj.products.push({
            id: this.basket[i].id,
            value: this.basket[i].select_type.value,
            count: this.basket[i].select_count
          })
        }
      }
      this.fetchPdf(obj).then(()=>{
        this.convertPdf()
      })
    }
  }
}